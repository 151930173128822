let langs = document.querySelector(".langs"),
link = document.querySelectorAll(".lang-opt"),
intro1 = document.querySelector(".lang__intro1"),
intro2 = document.querySelector(".lang__intro2"),
intro3 = document.querySelector(".lang__intro3"),
intro4 = document.querySelector(".lang__intro4"),
intro5 = document.querySelector(".lang__intro5");
presentation = document.querySelector(".lang__intro-presentation");

aboutMeLink = document.querySelector(".lang__aboutme--link");
aboutMeSpan = document.querySelector(".lang__aboutme--span");
ProjectsLink = document.querySelector(".lang__project--link");
ProjectsSpan = document.querySelector(".lang__project--span");
ContactBtnSpan = document.querySelector(".lang__btn-contact--span");

SkillsSpan = document.querySelector(".lang__skills-title");

AboutMeSectionTitle = document.querySelector(".lang__aboutme-section--title");
AboutMeHeaderTitle = document.querySelector(".lang__aboutme-header--title");

ProjectsSectionTitle = document.querySelector(".lang__projects-section--title");
ProjectsHeaderTitle = document.querySelector(".lang__projects-header--title");
ProjectLevelBuddyDescription = document.querySelector(".lang__project-level-buddy--description");
ProjectPongDescription = document.querySelector(".lang__project-pong--description");
ProjectPongSpan = document.querySelector(".lang__project-pong--span");

FaqQ1 = document.querySelector('.lang__faq--q1');
FaqQ2 = document.querySelector('.lang__faq--q2');
FaqQ3 = document.querySelector('.lang__faq--q3');
FaqQ4 = document.querySelector('.lang__faq--q4');
FaqQ5 = document.querySelector('.lang__faq--q5');

FaqA1 = document.querySelector('.lang__faq--a1');
FaqA2 = document.querySelector('.lang__faq--a2');
FaqA3 = document.querySelector('.lang__faq--a3');
FaqA4 = document.querySelector('.lang__faq--a4');
FaqA5 = document.querySelector('.lang__faq--a5');

ContactSectionTitle = document.querySelector(".lang__contact-section--title");
ContactHeaderTitle = document.querySelector(".lang__contact-header--title");
ContactLabel1 = document.querySelector(".lang__contact-label1");
ContactLabel2 = document.querySelector(".lang__contact-label2");
ContactLabel3 = document.querySelector(".lang__contact-label3");
ContactLabel4 = document.querySelector(".lang__contact-label4");
ContactInput1 = document.querySelector(".lang__contact-input1");
ContactInput2 = document.querySelector(".lang__contact-input2");
ContactInput3 = document.querySelector(".lang__contact-input3");
ContactTextArea = document.querySelector(".lang__contact-textarea");
ContactBtnSendSpan = document.querySelector(".lang__contact-send--span");

link.forEach(el=>{
     el.addEventListener("click", ()=>{
          alert("Work in progress");
          return;
          let currentlyActive = langs.querySelector(".active");
          currentlyActive.style.color ="#ffffff";
          currentlyActive.classList.remove("active");

          el.classList.add("active");
          el.style.color = "#f34040";
          
          let attr = el.getAttribute("language")

          // language texts replace

          // Landing section
          intro1.textContent = data[attr].intro1;
          intro2.textContent = data[attr].intro2;
          intro3.textContent = data[attr].intro3;
          intro4.textContent = data[attr].intro4;
          intro5.textContent = data[attr].intro5;
          presentation.textContent = data[attr].presentation;

          aboutMeSpan.textContent = data[attr].aboutMeLink;
          aboutMeLink.setAttribute("data-text", data[attr].aboutMeLink);

          ProjectsSpan.textContent = data[attr].projectsLink;
          ProjectsLink.setAttribute("data-text", data[attr].projectsLink);

          ContactBtnSpan.textContent = data[attr].btnContact;

          // Skills section
          SkillsSpan.textContent = data[attr].skillsTitle;

          // About section
          AboutMeSectionTitle.textContent = data[attr].aboutMeSectionTitle;
          AboutMeHeaderTitle.textContent = data[attr].aboutMeHeaderTitle;

          // Projects section
          ProjectsSectionTitle.textContent = data[attr].projectsSectionTitle;
          ProjectsHeaderTitle.textContent = data[attr].projectsHeaderTitle;
          ProjectLevelBuddyDescription.textContent = data[attr].levelbuddyDescription;
          ProjectPongDescription.textContent = data[attr].pongDescription;
          ProjectPongSpan.textContent = data[attr].pongSpan;

          // FAQ section
          FaqA1.textContent = data[attr].FaqA1;
          FaqA2.textContent = data[attr].FaqA2;
          FaqA3.textContent = data[attr].FaqA3;
          FaqA4.textContent = data[attr].FaqA4;
          FaqA5.textContent = data[attr].FaqA5;

          FaqQ1.textContent = data[attr].FaqQ1;
          FaqQ2.textContent = data[attr].FaqQ2;
          FaqQ3.textContent = data[attr].FaqQ3;
          FaqQ4.textContent = data[attr].FaqQ4;
          FaqQ5.textContent = data[attr].FaqQ5;

          // Contact section
          ContactSectionTitle.textContent = data[attr].contactSectionTitle;
          ContactHeaderTitle.textContent = data[attr].contactHeaderTitle;
          ContactLabel1.textContent = data[attr].contactLabel1;
          ContactLabel2.textContent = data[attr].contactLabel2;
          ContactLabel3.textContent = data[attr].contactLabel3;
          ContactLabel4.textContent = data[attr].contactLabel4;
          ContactInput1.setAttribute("placeholder",data[attr].contactInputPlaceholder1);
          ContactInput2.setAttribute("placeholder",data[attr].contactInputPlaceholder2);
          ContactInput3.setAttribute("placeholder",data[attr].contactInputPlaceholder3);
          ContactTextArea.setAttribute("placeholder",data[attr].contactTextAreaPlaceholder);
          ContactBtnSendSpan.textContent = data[attr].contactBtnSendSpan;
     })
})

let data = {
     english: {
          intro1: "Hi 👋, my name is",
          intro2: "Alessandro Bonomo",
          intro3: "Creative Software Engineer,",
          intro4: "Embedded developer",
          intro5: "from Italy 🌍",
          presentation: `
          Hello, I'm a Software Engineer with extensive experience in web and mobile application development, as well as embedded systems such as Raspberry Pi and Nucleo STM32. I specialize as a full stack developer, Internet of Things, and embedded systems development.
          My approach is to understand the client's needs and develop customized solutions to meet them. I am capable of developing high-quality web and mobile applications, integrating cutting-edge technologies such as React, Flask, Node.js e Flutter. I also have extensive experience using databases such as MySQL, MySqlite, MySqlAlchemy, Firebase e PostgreSQL.
          Regarding embedded systems development, I have a broad knowledge of Linux and its components, as well as programming low-level devices such as Raspberry Pi and Nucleo STM32. Additionally, I have experience using communication protocols TCP/UDP, Sockets and RESTful API to create complex IoT systems.
          Furthermore, in my professional activity, I pay particular attention to the security and privacy of the software I develop. I am an expert in implementing security measures such as user authentication, data encryption, and vulnerability management, to ensure the protection of clients' sensitive information.
          Additionally, I am always up-to-date on privacy regulations and laws regarding personal data protection, in order to ensure the applications I develop are compliant with current regulations.
          I am always looking for new challenges and enjoy tackling projects of various sizes, from the simplest to the most complex. If you are looking for a software development expert who is capable of developing tailored solutions for your needs, do not hesitate to contact me.
          `,
          aboutMeLink: "About me",
          projectsLink: "Projects",
          btnContact: "Contact",
          skillsTitle: "My Skills",
          aboutMeSectionTitle: "/About me",
          aboutMeHeaderTitle: "Something about me",
          projectsSectionTitle: "/Projects",
          projectsHeaderTitle: "Some of my lastest works",
          levelbuddyDescription: `
          Temporary description
          `,
          pongDescription: `
          I have developed the well-known video game Pong using assembly language and played it on MS-DOS in a virtual machine. To develop the game, I utilized emu8086 as the development environment. After extensively testing the code, I compiled it and produced an executable file, which I saved onto a virtual floppy disk. To run the game, I started MS-DOS in VMware and mounted the floppy disk. Finally, I was able to successfully run Pong.
          Pong is a classic arcade game that was first released in 1972 by Atari. It involves two paddles moving up and down on opposite sides of the screen, with the objective being to hit a ball back and forth between them without missing. The game has been remade countless times since its original release and is still enjoyed by gamers today.
          Developing Pong using assembly language was a challenging but rewarding experience. Assembly language is a low-level programming language that requires a deep understanding of the computer's hardware and memory management. By using emu8086 as the development environment, I was able to simulate the hardware and test my code efficiently.
          Compiling the code and producing an executable file was a crucial step in the development process. The executable file contained the compiled code that the computer could run, allowing me to play the game without having to type out the code each time.
          Overall, developing and playing Pong on MS-DOS in a virtual machine was a fun and educational experience. It allowed me to gain a deeper understanding of assembly language programming, hardware simulation, and operating systems.
          `,
          pongSpan: `
          Proof of concept
          `,
          FaqA1:`
          I am available to work all over the world and have experience working with international clients. Thanks to modern technology, I can work remotely and maintain constant communication with my customers, wherever they are.
          `,
          FaqA2:`
          Yes, I have worked with several small businesses to help them develop custom software and improve their work processes.
          `,
          FaqA3:`
          Development time depends on the specific project requirements. However, once I have received the project specifications, I will be able to provide you with an estimate of the time required to complete the work.
          `,
          FaqA4:`
          I implement security measures such as user authentication, data encryption, and vulnerability management, to ensure the protection of clients' sensitive information. Additionally, I am always up-to-date on privacy regulations and laws regarding personal data protection, in order to ensure the applications I develop are compliant with current regulations.
          `,
          FaqA5:`
          During software development, I ensure to create an intuitive and user-friendly user interface. Additionally, I will provide you with adequate training on work processes and software usage.
          `,
          FaqQ1:`
          Do you work internationally?
          `,
          FaqQ2:`
          Do you have experience helping small businesses?
          `,
          FaqQ3:`
          How long does it take to develop custom software?
          `,
          FaqQ4:`
          What are your security standards for the projects developed?
          `,
          FaqQ5:`
          How can I know that the software you develop is easy for me or my employees to use?
          `,
          contactSectionTitle: "/Contact",
          contactHeaderTitle: "Get in touch",
          contactLabel1: "Hey, my name is",
          contactLabel2: "I'm looking for",
          contactLabel3: "Get in touch with me",
          contactLabel4: "I agree with the storage and handling of my data by this website. -",
          contactInputPlaceholder1: "Type Here",
          contactInputPlaceholder2: "Type of Service",
          contactInputPlaceholder3: "Your email here",
          contactTextAreaPlaceholder: "Your message",
          contactBtnSendSpan: "Send",
     },
     italian: {
          intro1: "Ciao 👋, mi chiamo",
          intro2: "Alessandro Bonomo",
          intro3: "sono un Software Engineer,",
          intro4: "Embedded developer",
          intro5: "dall' Italia 🌍",
          presentation:`
          Ciao, sono un Software Engineer con una vasta esperienza nel campo dello sviluppo di applicazioni web e mobile, nonché di sistemi embedded come Raspberry Pi e Nucleo STM32. Sono specializzato come full stack developer, Internet of Things e sviluppo di sistemi embedded.
          Il mio approccio consiste nel capire le esigenze del cliente e sviluppare soluzioni personalizzate per soddisfarle. Sono in grado di sviluppare applicazioni web e mobile di alta qualità, integrando tecnologie all'avanguardia come React, Flask, Node.js e Flutter. Ho anche una vasta esperienza nell'utilizzo di database come MySQL, MySqlite, MySqlAlchemy, Firebase e PostgreSQL.
          Per quanto riguarda lo sviluppo di sistemi embedded, ho una vasta conoscenza di Linux e dei suoi componenti, nonché della programmazione di dispositivi a basso livello come Raspberry Pi e Nucleo STM32. Inoltre, ho esperienza nell'utilizzo di protocolli di comunicazione TCP/UDP, Sockets e RESTful API per creare sistemi IoT complessi.
          Inoltre, nella mia attività professionale, dedico particolare attenzione alla sicurezza e alla privacy delle applicazioni che sviluppo. Sono esperto nell'implementazione di misure di sicurezza, come l'autenticazione degli utenti, la crittografia dei dati e la gestione delle vulnerabilità, al fine di garantire la protezione delle informazioni sensibili dei clienti.
          Inoltre, sono sempre aggiornato sulle normative in materia di privacy e sulla regolamentazione delle leggi sulla protezione dei dati personali, al fine di assicurare la conformità delle applicazioni sviluppate alle normative in vigore.
          Sono sempre alla ricerca di nuove sfide e mi piace affrontare progetti di varie dimensioni, dai più semplici ai più complessi. Se sei alla ricerca di un esperto in sviluppo software che sia in grado di sviluppare soluzioni su misura per le tue esigenze, non esitare a contattarmi.
          `,
          aboutMeLink: "Chi sono",
          projectsLink: "Progetti",
          btnContact: "Contattami",
          skillsTitle: "Le mie Skills",
          aboutMeSectionTitle: "/Chi sono",
          aboutMeHeaderTitle: "Qualcosa su di me",
          projectsSectionTitle: "/Progetti",
          projectsHeaderTitle: "Alcuni dei miei ultimi lavori",
          levelbuddyDescription: `
          Descrizione temporanea
          `,
          pongDescription: `
          Ho sviluppato il famoso videogioco Pong utilizzando il linguaggio assembly e l'ho giocato su MS-DOS in una macchina virtuale. Per sviluppare il gioco ho utilizzato emu8086 come ambiente di sviluppo. Dopo aver testato ampiamente il codice, l'ho compilato e ho prodotto un file eseguibile, che ho salvato su un floppy disk virtuale. Per eseguire il gioco, ho avviato MS-DOS in VMware e ho montato il floppy disk. Infine, sono riuscito a eseguire Pong con successo.
          Pong è un classico gioco arcade che è stato pubblicato per la prima volta nel 1972 da Atari. Coinvolge due palette che si muovono su e giù su lati opposti dello schermo, con l'obiettivo di colpire una palla avanti e indietro tra di loro senza sbagliare. Il gioco è stato riproposto innumerevoli volte dal suo lancio originale ed è ancora apprezzato dai giocatori oggi.
          Sviluppare Pong utilizzando il linguaggio assembly è stata un'esperienza impegnativa ma gratificante. Il linguaggio assembly è un linguaggio di programmazione a basso livello che richiede una profonda comprensione dell'hardware del computer e della gestione della memoria. Utilizzando emu8086 come ambiente di sviluppo, sono stato in grado di simulare l'hardware e testare il mio codice in modo efficiente.
          La compilazione del codice e la produzione di un file eseguibile sono state fasi cruciali nel processo di sviluppo. Il file eseguibile conteneva il codice compilato che il computer poteva eseguire, permettendomi di giocare al gioco senza dover digitare il codice ogni volta.
          In generale, lo sviluppo e il gioco di Pong su MS-DOS in una macchina virtuale è stata un'esperienza divertente ed educativa. Mi ha permesso di acquisire una conoscenza più approfondita della programmazione in linguaggio assembly, della simulazione dell'hardware e dei sistemi operativi.
          `,
          pongSpan: `
          Esercizio di stile
          `,
          FaqA1:`
          Sono disponibile per lavorare in tutto il mondo e ho esperienza nel lavorare con clienti internazionali. Grazie alla tecnologia moderna, posso lavorare a distanza e mantenere una comunicazione costante con i miei clienti, ovunque essi si trovino.
          `,
          FaqA2:`
          Sì, ho lavorato con diverse piccole imprese per aiutarle a sviluppare software personalizzato e migliorare i loro processi di lavoro.
          `,
          FaqA3:`
          Il tempo di sviluppo dipende dalle specifiche esigenze del progetto. Tuttavia, una volta che avrò ricevuto le specifiche del progetto, sarò in grado di fornirti una stima del tempo necessario per completare il lavoro.
          `,
          FaqA4:`
          Implemento misure di sicurezza, come l'autenticazione degli utenti, la crittografia dei dati e la gestione delle vulnerabilità, al fine di garantire la protezione delle informazioni sensibili dei clienti. Inoltre, sono sempre aggiornato sulle normative in materia di privacy e sulla regolamentazione delle leggi sulla protezione dei dati personali, al fine di assicurare la conformità delle applicazioni sviluppate alle normative in vigore.
          `,
          FaqA5:`
          Durante lo sviluppo del software, mi assicuro di creare un'interfaccia utente intuitiva e user-friendly. Inoltre, ti fornirò un'adeguata formazione sui processi di lavoro e l'uso del software.
          `,
          FaqQ1:`
          Lavori solo in Italia?
          `,
          FaqQ2:`
          Hai esperienza nell'aiutare piccole imprese?
          `,
          FaqQ3:`
          Quanto tempo ci vuole per sviluppare un software personalizzato?
          `,
          FaqQ4:`
          Quali sono i tuoi standard di sicurezza per i progetti sviluppati?
          `,
          FaqQ5:`
          Come posso sapere che il software che sviluppi è facile da usare per me o per i miei dipendenti?
          `,
          contactSectionTitle: "/Contattami",
          contactHeaderTitle: "Entra in contatto con me",
          contactLabel1: "Ciao, mi chiamo",
          contactLabel2: "Sto cercando",
          contactLabel3: "Ecco la mia email",
          contactLabel4: "Acconsento al trattamento dei dati immessi nel modulo di contatto. -",
          contactInputPlaceholder1: "Il tuo nome",
          contactInputPlaceholder2: "Tipo di servizio",
          contactInputPlaceholder3: "La tua email",
          contactTextAreaPlaceholder: "Il tuo messaggio",
          contactBtnSendSpan: "Invia",
     }
}
